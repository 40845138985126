* {
  box-sizing: border-box;
}

body {
  width: 100vw;
  margin: 0;
  font-family: 'montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 90%;
  width: 90%;
  display: flex;
  justify-content: center;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 90%;
  width: 90%;
}

ul {
  list-style-type: none;
  padding: 0 !important;
  margin-bottom: 30px;
}

.service-list-item {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  color: black; 
}
.service-list-item:hover {
  text-decoration: underline;
}

h1 {
  color: #3C3C3C;
}
